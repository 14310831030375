








// @ts-ignore
import { DashboardTransferPageTs } from './DashboardTransferPageTs';
export default class DashboardTransferPage extends DashboardTransferPageTs {}
